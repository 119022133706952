import { Box, Switch, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import ArrowDown from "../../assets/icons/arrowDown";
import ArrowUp from "../../assets/icons/arrowUp";
import EmptyPlaceholder from "../../atoms/EmptyPlacholder";
import Loader from "../../atoms/Loader";
import Pill from "../../atoms/Pill";
import { getPillStatusIcon } from "../../atoms/PillStatusIcon";
import { getPillStatus } from "../../constants/status.constants";
import { SortDirection } from "./enum";
import PillDropdown from "../../atoms/PillDropdown";
import DatePickerInTable from "../../atoms/DatePickerInTable";
import { dateFn, followUpDateFormatter } from "../../utils/utils";
import { useDraggable } from "react-use-draggable-scroll";
import Edit from "../../assets/icons/edit";
import Save from "../../assets/icons/save";
import {
  MediaContext,
  MEDIA_TYPES,
  MediaContextProps,
} from "../../context/MediaContext";
import EditIcon from "../../assets/icons/editIcon";
import Cloud from "../../assets/icons/cloud";
import ExportToCsv from "../../atoms/ExportToCsvV2";
import { leadStatus_column } from "../../pages/detail/constants";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";

function emptyFunction() {}

const CustomTable = ({
  rows,
  columns,
  tableStriped,
  search = "",
  emptySearchMetaData,
  emptyTableMetaData,
  handleNextPage,
  handleSorting = () => {},
  sortKey,
  sortDirection,
  hasMore,
  options,
  handleClickedPill,
  control,
  errors,
  handleDateFn,
  handleListChange,
  editname,
  handleSave,
  listNameDisable,
  dataTestId,
  handleSaveKeyPress,
  handleEditName,
  popupClicked,
  exportListLoading,
  loadingText
}: any) => {
  let navigate = useNavigate();
  const customTableContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const customTableLoaderRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const { events } = useDraggable(customTableContainerRef);
  const [tableData, setTableData]: any = React.useState(rows);
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null);
  const hasMoreRef = useRef<boolean>(false);
  hasMoreRef.current = hasMore;
  const { media } = useContext(MediaContext) as MediaContextProps;

  const handleTitleDescField = (row: any) => {
    editname(row?.id);
    let inputField = document.getElementById(`listInputId${row?.id}`);
    inputField?.focus();
  };
  useEffect(() => {
    setTableData(rows);
  }, [rows]);

  useEffect(() => {
    if (!rows?.length) {
      return;
    }

    intersectionObserverRef.current = new IntersectionObserver(
      (entries, opts) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting || !hasMoreRef.current) {
            return;
          }
          handleNextPage();
        });
      },
      {
        root: customTableContainerRef.current,
        threshold: 0.5,
      }
    );

    let tableLoader = customTableLoaderRef.current;

    if (tableLoader) {
      intersectionObserverRef.current.observe(tableLoader);
    }

    return () => {
      if (tableLoader) {
        intersectionObserverRef.current?.unobserve(tableLoader);
      }
      intersectionObserverRef.current?.disconnect();
    };
  }, [rows]);

  return (
    <div className={`custom-table ${tableStriped ? `table-striped` : ``}`}>
      <div
        className="custom-table-wrapper"
        id="scrollableDiv"
        ref={customTableContainerRef}
        {...events}
      >
        {/* <ScrollContainer className="scroll-container"> */}
        <InfiniteScroll
          dataLength={tableData?.length ?? 0}
          next={() => handleNextPage && handleNextPage()}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          loader={
            <div className="table-loader" ref={customTableLoaderRef}>
              <Loader></Loader>
            </div>
          }
        >
          <Table stickyHeader aria-label="sticky table">
            {rows?.length > 0 && (
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      onClick={() => handleSorting(column.sortKey ?? "")}
                    >
                      {column.sortKey ? (
                        <>
                          <span
                            role="link"
                            className="flex alignCenter table-shorting"
                          >
                            {" "}
                            {column.label}{" "}
                            {sortKey && sortKey === column.sortKey && (
                              <span className="shortIcon">
                                {SortDirection.ASC === sortDirection ? (
                                  <ArrowUp />
                                ) : (
                                  <ArrowDown />
                                )}
                              </span>
                            )}
                          </span>
                        </>
                      ) : (
                        <>{column.label}</>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {rows?.length ? (
                rows.map((row: any, rowIndex: number) => {
                  if (row?.status === "Cancelled") return null;
                  else {
                    return (
                      <>
                        {row?.isLoading ? (
                          <TableRow>
                            <TableCell colSpan={columns?.length}>
                              <div className="table-loader">
                                <Loader></Loader>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow
                            hover
                            role={row?.handleOnClick ? "link" : "checkbox"}
                            tabIndex={-1}
                            key={row?.code || row?.id}
                            onClick={row?.handleOnClick ?? emptyFunction}
                            className={
                              row?.id == row?.activityDrawerLeadId
                                ? "activity-highlight"
                                : ""
                            }
                            data-testid={dataTestId}
                          >
                            {columns.map((column: any) => {
                              const value: any = row[column?.id];
                              switch (column.type) {
                                case "number":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                    >
                                      {column?.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                  case "numberPopup":
                                    return (
                                      <TableCell
                                        key={column?.id}
                                        align={column?.align}
                                      >
                                        {exportListLoading[value] !== true ? (
                                        <span
                                          className="custom-popup"
                                          role={value === "—" ? "" :"link"}
                                        >
                                          {column?.format &&
                                          typeof value === "number"
                                            ? column.format(value)
                                            : value}
                                          <span
                                            className={value === "—" ? "custom-popup__hide" : "custom-popup__inner"}
                                            role={value === "—" ? "" :"link"}
                                            onClick={()=>console.log("inside column",column?.id,value,row.id)}
                                          >
                                            <ExportToCsv
                                              headers={leadStatus_column.map(
                                                (column: any) => {
                                                  return {
                                                    id: column.key,
                                                    displayName: column.label,
                                                  };
                                                }
                                              )}
                                              filename={`${column?.id}.csv`}
                                              loadData={()=>popupClicked(row.id,column?.id,value)}
                                              loading={exportListLoading[value]}
                                            ></ExportToCsv>
                                          </span>
                                        </span>
                                        ):(
                                            <Box sx={{
                                              textAlign: 'center',
                                              width: '100%',
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '5px',
                                              fontSize: '10px',
                                              paddingTop:'25px',
                                              marginBottom: '10px',
                                              position: 'relative',
                                            }}><Loader size={10} /> {loadingText}</Box>
                                          )}
                                      </TableCell>
                                    );
                                case "status":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                    >
                                      <Tooltip
                                        title={value?.toolTipText}
                                        placement="top"
                                      >
                                        <div>
                                          <Pill
                                            label={
                                              value?.label
                                                ? value?.label
                                                : value
                                            }
                                            className={getPillStatus(
                                              value?.label
                                                ? value?.label
                                                : value
                                            )}
                                            infix={getPillStatusIcon(
                                              value?.label
                                                ? value?.label
                                                : value
                                            )}
                                          />
                                        </div>
                                      </Tooltip>
                                    </TableCell>
                                  );
                                case "toggle":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                    >
                                      <Switch
                                        checked={value}
                                        onChange={() => {
                                          column?.metaData?.handleChange(
                                            rowIndex,
                                            column?.id
                                          );
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    </TableCell>
                                  );
                                case "date":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                    >
                                      {new Date(value).toDateString()}
                                    </TableCell>
                                  );
                                case "link":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                    >
                                      <a
                                        className="view"
                                        onClick={() => {
                                          navigate(`/${value}`);
                                        }}
                                      >
                                        View
                                      </a>
                                    </TableCell>
                                  );
                                case "name":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                    >
                                      <span
                                        className="campaign-name"
                                        onClick={() => {
                                          navigate(`/campaigns/${row?.id}`);
                                        }}
                                        role="link"
                                      >
                                        {value}
                                      </span>
                                    </TableCell>
                                  );
                                case "icon":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column.align}
                                    >
                                      <span className="inlineFlex" role="link">
                                        {value}{" "}
                                      </span>
                                    </TableCell>
                                  );
                                case "titleDescription":
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <div className="flex tableCopy alignCenter justifySpaceBetween">
                                        <div
                                          className="titleDesc"
                                          // role="link"
                                          // onClick={
                                          //   row?.handleOnClicked ??
                                          //   emptyFunction
                                          // }
                                          data-testid="titleCustomTableId"
                                        >
                                          {!value?.tooltipRequired ? (
                                            <p>
                                              {value?.title}{" "}
                                              {/* {value?.copyLeadName
                                                ? value?.copyLeadName
                                                : ""} */}
                                            </p>
                                          ) : (
                                            <h2>
                                              <input
                                                type="text"
                                                id={`listInputId${row?.id}`}
                                                value={value?.title}
                                                readOnly={value?.notEdittable}
                                                onChange={handleListChange}
                                                onKeyDown={(e) =>
                                                  handleSaveKeyPress(e)
                                                }
                                                className={`${
                                                  value?.tooltipRequired &&
                                                  !value?.notEdittable &&
                                                  "activeInput"
                                                }`}
                                                autoFocus={
                                                  value?.tooltipRequired &&
                                                  !value?.notEdittable
                                                }
                                                data-testid={
                                                  !value?.notEdittable
                                                    ? "editList"
                                                    : ""
                                                }
                                              />
                                              {value?.copyLeadName
                                                ? value?.copyLeadName
                                                : ""}
                                            </h2>
                                          )}

                                          {value?.status === "uploading" ? (
                                            <div>
                                              Processing
                                              <span className="yellowDot"></span>
                                            </div>
                                          ) : value?.status === "failed" ? (
                                            <div>
                                              {value?.description}
                                              <span className="redDot"></span>
                                            </div>
                                          ) : value?.status === "success" ? (
                                            <div>
                                              {value?.description}

                                              {(value?.description ===
                                                "Purchased" ||
                                                value?.description ===
                                                  "Uploaded") && (
                                                <span className="greenDot"></span>
                                              )}
                                            </div>
                                          ) : (
                                            <>
                                              <span
                                                className="titleDesc__blue"
                                                role="link"
                                                onClick={
                                                  row?.handleOnClicked ??
                                                  emptyFunction
                                                }
                                              >
                                                {value?.description}
                                              </span>{" "}
                                              {value?.copyAddress
                                                ? value?.copyAddress
                                                : ""}
                                            </>
                                          )}
                                        </div>

                                        {value?.editname ? value?.editname : ""}
                                        {value?.tooltipRequired &&
                                          (value?.notEdittable ? (
                                            <Tooltip
                                              title={row?.role === PermissionListEnum.ORGANISATION_VIEWER
                                                ? ROLE_PERMISSION_STATUS.NoAccess
                                                : `Rename ${
                                                value?.copyLeadName
                                                  ? "Lead"
                                                  : "List"
                                              }` }
                                              key={row?.id}
                                            >
                                              <button
                                                role="link"
                                                className="edit-save-icon"
                                                onClick={() =>
                                                  handleTitleDescField(row)
                                                }
                                                disabled={listNameDisable ||row?.role === PermissionListEnum.ORGANISATION_VIEWER}
                                                data-testid="renamelist"
                                                key={row?.id}
                                              >
                                                <Edit />
                                              </button>
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              title={`Save ${
                                                value?.copyLeadName
                                                  ? "lead"
                                                  : "list"
                                              } Name`}
                                              key={row?.id}
                                            >
                                              <button
                                                role="link"
                                                key={row?.id}
                                                className="edit-save-icon"
                                                onClick={handleSave}
                                                disabled={listNameDisable}
                                                data-testid="saveList"
                                              >
                                                <Save />
                                              </button>
                                            </Tooltip>
                                          ))}
                                      </div>
                                    </TableCell>
                                  );
                                case "titleDescriptionText":
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <div
                                        className="titleDesc"
                                        data-testid="titleCustomTableId"
                                      >
                                        <div className="flex tableCopy alignCenter ">
                                          {value?.notEdittable && (
                                            <p>{value?.title}</p>
                                          )}
                                          {value?.copyLeadName
                                            ? value?.copyLeadName
                                            : ""}
                                          {
                                            value?.tooltipRequired ? (
                                              <Tooltip
                                                title={row?.role === PermissionListEnum.ORGANISATION_VIEWER
                                                  ? ROLE_PERMISSION_STATUS.NoAccess
                                                  : `Rename Lead` }
                                                
                                                key={row?.id}
                                              >
                                                <button
                                                  role="link"
                                                  className="edit-save-icon-transparent"
                                                  onClick={() => {
                                                    handleEditName(
                                                      value?.title,
                                                      row?.id
                                                    );
                                                  }}
                                                  disabled={listNameDisable || row?.role === PermissionListEnum.ORGANISATION_VIEWER}
                                                  data-testid="renamelist"
                                                  key={row?.id}
                                                >
                                                  <EditIcon />
                                                </button>
                                              </Tooltip>
                                            ) : null
                                          }
                                        </div>
                                        <span
                                          className="titleDesc__blue"
                                          role="link"
                                          onClick={
                                            row?.handleOnClicked ??
                                            emptyFunction
                                          }
                                        >
                                          {value?.description}
                                        </span>{" "}
                                        {value?.copyAddress
                                          ? value?.copyAddress
                                          : ""}
                                      </div>
                                    </TableCell>
                                  );
                                case "statusPill":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                      data-testid="statusEditLeadsTestId"
                                    >
                                      {row?.rootUser ? (
                                        <span className="root-user">
                                          {row?.roleName}{" "}
                                        </span>
                                      ) : (
                                        <Tooltip
                                          title={
                                            row?.role ===
                                            PermissionListEnum.ORGANISATION_VIEWER
                                              ? ROLE_PERMISSION_STATUS.NoAccess
                                              : ""
                                          }
                                          key={row?.id}
                                        >
                                          <div>
                                          <PillDropdown
                                            options={options}
                                            label={
                                              value?.label
                                              ? value?.label
                                              : value
                                            }
                                            disabled={row?.role === PermissionListEnum.ORGANISATION_VIEWER}
                                            onChange={(value: any) =>
                                              handleClickedPill({
                                                id: row?.id,
                                                value: value,
                                                flag: row?.flag
                                                ? row?.flag
                                                : false,
                                              })
                                            }
                                            />
                                            </div>
                                          
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  );
                                case "dateCalenderCell":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                      data-testid="dateEditLeadsTestId"
                                    >
                                      <div className="tableDatePicker">
                                      <Tooltip
                                                title={row?.role === PermissionListEnum.ORGANISATION_VIEWER
                                                  ? ROLE_PERMISSION_STATUS.NoAccess
                                                  : "" }
                                                
                                                key={row?.id}
                                              >
                                                <span>
                                        <DatePickerInTable
                                          name={`follow_up_at_${row?.id}`}
                                          errors={errors}
                                          control={control}
                                          disabled={row?.role === PermissionListEnum.ORGANISATION_VIEWER}
                                          calenderCheckIcon={true}
                                          defaultValue={
                                            row?.followUpDate !== "None"
                                            ? dateFn(row?.followUpDate)
                                            : null
                                          }
                                          onDateChange={(value: any) => {
                                            handleDateFn({
                                              id: row?.id,
                                              followUpAt: value,
                                            });
                                          }}
                                          placeHolder="Pick a date"
                                          />
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  );
                                case "clampedText": {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {media !== MEDIA_TYPES.DESKTOP ? (
                                        <Tooltip
                                          title={
                                            value === "-" || value == null
                                              ? ""
                                              : value
                                          }
                                          enterDelay={1000}
                                          enterNextDelay={1000}
                                        >
                                          <span className="clampedText">
                                            {value ?? "-"}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <>
                                          <div className="flex tableCopyRow alignCenter justifySpaceBetween">
                                            <span className="clampedText">
                                              {value ?? "-"}
                                            </span>
                                            <span>
                                              {value === "-"
                                                ? ""
                                                : row?.copySummary
                                                ? row?.copySummary
                                                : ""}
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </TableCell>
                                  );
                                }
                                case "phone": {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <div className="flex tableCopyRow alignCenter">
                                        <span className="phone-no bold-text">
                                          {value ?? "-"}
                                        </span>
                                        <span>
                                          {value === "-"
                                            ? " "
                                            : value === row?.phone
                                            ? row?.copyPhone
                                              ? row?.copyPhone
                                              : ""
                                            : ""}
                                        </span>
                                      </div>
                                    </TableCell>
                                  );
                                }
                                case "Pill":
                                  return (
                                    <TableCell
                                      key={column?.id}
                                      align={column?.align}
                                      data-testid="statusEditLeadsTestId"
                                    >
                                      <Pill label={
                                              value?.label
                                                ? value?.label
                                                : value
                                            }
                                            className={getPillStatus(
                                              value?.status
                                                ? value?.status
                                                : value
                                            )}
                                            infix={getPillStatusIcon(
                                              value?.status
                                                ? value?.status
                                                : value
                                            )}
                                          />
                                    </TableCell>
                                  );
                                default:
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <div className="flex tableCopyRow alignCenter justifySpaceBetween">
                                        <span>{value ?? "-"}</span>
                                        <span>
                                          {value === "-"
                                            ? " "
                                            : value === row?.phone
                                            ? row?.copyPhone
                                              ? row?.copyPhone
                                              : ""
                                            : ""}
                                        </span>
                                      </div>
                                    </TableCell>
                                  );
                              }
                            })}
                          </TableRow>
                        )}
                      </>
                    );
                  }
                })
              ) : search.length > 0 ? (
                <TableRow
                  className="noDataRow"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell colSpan={columns.length}>
                    {emptySearchMetaData && (
                      <EmptyPlaceholder
                        icon={emptySearchMetaData.icon}
                        title={emptySearchMetaData.title}
                        description={emptySearchMetaData.description}
                        button1={emptySearchMetaData.button1}
                        button2={emptySearchMetaData.button2}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  className="noDataRow"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell colSpan={columns.length}>
                    {emptyTableMetaData && (
                      <EmptyPlaceholder
                        icon={emptyTableMetaData.icon}
                        title={emptyTableMetaData.title}
                        description={emptyTableMetaData.description}
                        button1={emptyTableMetaData.button1}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* </TableContainer> */}
        </InfiniteScroll>
        {/* </ScrollContainer> */}
      </div>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </div>
  );
};

export default CustomTable;
