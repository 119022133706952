import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";

const ApproveConfirmation = ({
  open,
  handleClose,
  onSubmit,
  loading,
  isPaidSeat,
  userId,
}: any) => {
  return (
    <>
      <Dialog
        className={`stepsDialog contactInfoModal`}
        onClose={handleClose}
        open={open}
      >
        <div className="dialogWrapper">
          <div className="dialogWrapper__header">
            <span role="link" className="closeDialog" onClick={handleClose}>
              <Close />
            </span>
          </div>

          <>
            <div className="dialogWrapper__info">
              <h6>Please Confirm</h6>
              <p>
                {isPaidSeat
                  ? "This seat costs $20 and will be charged to your account."
                  : "This seat is complimentary, courtesy of the OttoLeads team. To add additional seats for $20 each, go to Settings > My Team, and click 'Add User' for a new seat."}
              </p>
            </div>
            <div className="actions">
              <div className="mainCol">
                <div className="mainCol__half">
                  <Button
                    label={"Cancel"}
                    action={handleClose}
                    className="outline animated full"
                  ></Button>
                </div>
                <div className="mainCol__half">
                  <Button
                    className="primary animated full"
                    label={"Confirm"}
                    disabled={loading}
                    clicked={loading}
                    action={() => onSubmit(userId)}
                  ></Button>
                </div>
              </div>
            </div>
          </>
        </div>
      </Dialog>
    </>
  );
};

export default ApproveConfirmation;
