import {
  Dialog,
  LinearProgress,
  Tooltip,
  linearProgressClasses,
  styled,
} from "@mui/material";
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  RegisterOptions,
  UseFormRegisterReturn,
  useForm,
} from "react-hook-form";
import Award from "../../assets/icons/award";
import HelpCircle from "../../assets/icons/helpCircle";
import Button from "../../atoms/Button";
import CustomDatePicker from "../../atoms/CustomDatePicker";
import DropDown from "../../atoms/DropDown";
import Loader from "../../atoms/Loader";
import { LeadStatusMap } from "../../constants/leadstatus.constants";
import { fbc, fbp, fetchIp, formartNumberAsLocalString, formatCurrency, hashNumber, hashPhoneNumber, hashString } from "../../utils/utils";
import Map, { MapViewMode } from "../../molecules/map";
import styles from "./PropertyDetails.module.scss";
import LeadActivityDrawer from "./leadDetailsModal/LeadDetailsModal";
import {
  fetchPropertyDetailsAction,
  propertyUpdateDetailsAction,
} from "./property.sagaAction";
import { getPropertyDetails, isLoading } from "./property.selector";
import PropertyTabs from "./propertyTabs/PropertyTab";
import MarkerPopup from "../../molecules/markerPopup/MarkerPopup";
import { useHelpModalContext } from "../../context/HelpModalContext";
import { pageNameFn, PermissionListEnum } from "../../organisms/auth/enum";
import { updateProfileAction } from "../../components/profileMenu/sagaActions";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import WelcomeModal from "../../organisms/welcomeModal/WelcomeModal";
import {
  resetPropertyDetailsState,
  setPropertyDetails,
} from "./property.reducer";
import { ModalCloseReason } from "../../organisms/welcomeModal/interface";
import SingleSkipTraceModal from "../../organisms/singleSkipTraceModal/SingleSkipTraceModal";
import Edit from "../../assets/icons/edit";
import EditIcon from "../../assets/icons/editIcon";
import Input from "../../atoms/Input";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import {
  ownerNameUpdate,
  resetOwnerName,
} from "../../organisms/propertySearchFilter/PropertyService";
import { FbConversionApi } from "../../organisms/auth/authServices";
import AddContactModal from "../../organisms/addContactModal/AddContactModal";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    background: theme.palette.mode === "light" ? "#005DA4" : "#005DA4",
  },
}));

const LeadStatusOptions = Object.entries(LeadStatusMap).map(([key, value]) => ({
  label: key,
  value: value,
}));

const PropertyDetails = () => {
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const { status = "", follow_up_at = "", addedOwner } = propertyDetails;
  const isPropertyDetailsLoading = useSelector((state) => isLoading(state));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const nameRef = useRef("");
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showSingleSkipTraceModal, setshowSingleSkipTraceModal] =
    useState(false);
  const [editNameModal, setEditNameModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [ownerFullName, setOwnerFullName] = useState("");

  const {
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      follow_up_at: follow_up_at ?? "",
      status: status,
    },
  });
  const [selectedMarkers, setSelectedMarkers] = useState<any[]>([]);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [showModal, setShowModal] = useState(false);
  const { openWelcomeModal, modalContent, setModalContent } =
    useHelpModalContext();
  const location = useLocation();
  const page: any = pageNameFn(location?.pathname.split("/")[1]);
  const [showAddContactModal, setShowAddContactModal] =
  useState(false);
  const handleClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: ModalCloseReason
  ) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      dispatch(
        updateProfileAction({
          [page?.pagename]: true,
          organisationName: profileInfo?.organisation?.organisationName,
        })
      );
    }
    setShowModal(false);
  };

  useEffect(() => {
    setModalContent({
      ...modalContent,
      title: page?.title,
      subtitle: page?.subtitle,
      video: page?.video,
    });

    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      setShowModal(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetPropertyDetailsState({}));
    };
  }, []);

  useEffect(() => {
    if (follow_up_at || status) {
      // if(follow_up_at!==watch('follow_up_at')){
      //   setValue('follow_up_at',follow_up_at)
      // }
      // if((LeadStatusMap as any)?.[status]!==watch('status')){
      //   setValue('status',(LeadStatusMap as any)?.[status])
      // }
      reset({
        follow_up_at: follow_up_at,
        status: (LeadStatusMap as any)?.[status],
      });
    }
  }, [follow_up_at, status]);

  useEffect(() => {
    const updateName = addedOwner
      ? `${addedOwner.nameFirst} ${addedOwner?.nameLast ? addedOwner?.nameLast : ''}`
      : propertyDetails?.jsonData?.ownerInfo?.owner1FullName ?? "-";
      nameRef.current = updateName;
    setOwnerFullName(updateName);
  }, [propertyDetails]);

  const handleClickCommentBox = () => {
    setShowCommentBox(true);
  };

  const handleClickSkipTrace = () => {
    setshowSingleSkipTraceModal(true);
  };
  const handleClickAddContact= () => {
    setShowAddContactModal(!showSingleSkipTraceModal);
  };

  useEffect(() => {
    if (!param.id) return;
    dispatch(fetchPropertyDetailsAction({ id: param.id }));
  }, [dispatch, param.id]);

  useEffect(() => {
    if (propertyDetails?.jsonData?.id) {
      setSelectedMarkers([propertyDetails?.jsonData?.id]);
    }
  }, [propertyDetails]);
  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Property_Detail_View",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(profileInfo?.data?.result?.organisationId),
              },
            },
          ],
        };
  
        await FbConversionApi(payloadData);
      } catch (error) {
        console.error('Error sending payload:', error);
      }
    };
    fbConversionApiSend();
  }, [profileInfo]);

  if (isPropertyDetailsLoading) {
    return (
      <div className="flex alignCenter justifyCenter">
        <Loader />
      </div>
    );
  }

  if (!isPropertyDetailsLoading && !propertyDetails?.id) {
    navigate("/leads", { replace: true });
    return null;
  }

  const {
    propertyInfo,
    ownerInfo,
    currentMortgages,
    equityPercent,
    estimatedEquity,
    lastSalePrice,
    ownerOccupied,
    absenteeOwner,
    taxInfo,
    estimatedValue,
    mlsActive,
    mlsPending,
    mlsHistory,
    demographics,
    estimatedMortgageBalance,
    estimatedMortgagePayment,
  } = propertyDetails.jsonData;

  let addressStr = propertyInfo?.address?.label;
  let stateStr =
    propertyInfo?.address?.state?.[0]?.toUpperCase() +
      propertyInfo?.address?.state?.substring(1)?.toLowerCase() ?? "";
  const label = addressStr
    ? addressStr?.slice(0, addressStr?.lastIndexOf(stateStr)) +
      addressStr
        ?.slice(addressStr?.lastIndexOf(stateStr))
        ?.replace(stateStr, stateStr?.toUpperCase())
    : "-";

  const features: {
    [key: string]: any;
  } = {
    bed: propertyInfo?.bedrooms ? propertyInfo?.bedrooms : "-",
    bath: propertyInfo?.bathrooms ? propertyInfo?.bathrooms : "-",
    sqft: propertyInfo?.livingSquareFeet
      ? formartNumberAsLocalString(propertyInfo?.livingSquareFeet)
      : "-",
    "lot sqft": propertyInfo?.lotSquareFeet
      ? formartNumberAsLocalString(propertyInfo?.lotSquareFeet)
      : "-",
    "year built": propertyInfo?.yearBuilt ? propertyInfo?.yearBuilt : "-",
  };

  const handleDateChange = (date: any) => {
    // dispatch(propertyUpdateDetailsAction({ id: param.id, followUpAt: date?.split("T")[0], status:status }));
    dispatch(
      propertyUpdateDetailsAction({
        id: param.id,
        followUpAt: date?.split("T")[0] ?? null,
      })
    );
  };

  const handleStatusChange = (statusValue: any) => {
    dispatch(
      propertyUpdateDetailsAction({ id: param.id, status: statusValue?.label })
    );
  };

  const propertyfeaturesAsString = Object.keys(features)
    .map((key) => (features[key] ? `${features[key]} ${key}` : null))
    .filter(Boolean)
    .join(" | ");

  const handleEditName = () => {
    setEditNameModal(!editNameModal);
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleSaveName = async () => {
    if (nameRef.current === "") {
      handleShowToast("Owner name is required.", ToastType.error);
    } else if (nameRef.current === ownerFullName) {
      handleEditName();
    }else {
      setLoading(true);
      try {
        await ownerNameUpdate(param.id, { fullName: nameRef.current });
        setOwnerFullName(nameRef.current);
        handleShowToast(
          "Owner name is updated successfully!",
          ToastType.success
        );
        handleEditName();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleResetName = async () => {
    try {
      setResetLoading(true);
      await resetOwnerName(param.id);
      setOwnerFullName(propertyDetails.jsonData.ownerInfo.owner1FullName);
      handleShowToast("Owner name reset successfully!", ToastType.success);
      handleEditName();
      setResetLoading(false);
    } catch (error:any) {
      console.log(error);
      handleShowToast(error?.response?.statusText, ToastType.error);
      setResetLoading(false);
    }
  };

  const AboveMap = () => {
    return (
      <div className={` ${styles.property__top}`}>
        <div className="wrapper bottom-zero">
          <div
            className={` ${styles.propertyHeader} dflex justifySpaceBetween`}
          >
            <div className={` ${styles.propertyHeader__left}`}>
              <h2>
                {label}
                <Tooltip onClick={() => openWelcomeModal(page)} title={""}>
                  <span role="link">
                    <HelpCircle />
                  </span>
                </Tooltip>
              </h2>
              <p>{propertyfeaturesAsString}</p>
            </div>
            {showModal && (
              <WelcomeModal
                title={modalContent.title}
                subtitle={modalContent.subtitle}
                button={
                  <Button
                    action={handleClose}
                    className="primary xl full"
                    label="Got it"
                  />
                }
                video={modalContent.video}
                open={showModal}
                handleClose={handleClose}
              />
            )}
            <div
              className={`dflex alignCenter ${styles.propertyHeader__right}`}
            >
               <Tooltip
              title={
                profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <div>
              <CustomDatePicker
                label=""
                control={control}
                name="follow_up_at"
                errors={errors}
                placeHolder="Follow Up Date"
                clearable={true}
                disabled={profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER}
                onDateChange={handleDateChange}
              ></CustomDatePicker>
                </div>
                </Tooltip>
               <Tooltip
              title={
                profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <div>


              <DropDown
                options={LeadStatusOptions}
                clearOnBlur={true}
                label=""
                control={control}
                name="status"
                errors={errors}
                placeholder="Status"
                handleOnChange={handleStatusChange}
                disabled={profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER}
                />
                </div>
                </Tooltip>
              <Button
                label="Lead Activity"
                className="primary animated"
                prefix={<Award />}
                action={handleClickCommentBox}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BelowMap = () => {
    return (
      <div className={` ${styles.property__info}`}>
        <div className="wrapper bottom-zero">
          <div
            className={`dflex justifySpaceBetween ${styles.propertyDetails}`}
          >
            <div className={`${styles.propertyBox}`}>
              <h3>Owner Name</h3>
              <p className={`${styles.propertyBox__edit}`}>
                {ownerFullName}
                {profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ?
                <Tooltip
                title={ ROLE_PERMISSION_STATUS.NoAccess }
              >
                <span role="disable" >
                <EditIcon />
              </span>
              </Tooltip>:
                  
                  <span role="link" onClick={handleEditName}>
                  <EditIcon />
                </span>}
              </p>
              <h3>Occupancy</h3>
              <p>
                {ownerOccupied
                  ? "Owner Occupied"
                  : absenteeOwner
                  ? "Abstentee Owner"
                  : "Vacant"}
              </p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                Mortgages <span>{currentMortgages?.length}</span>
              </h3>
              <p>
                {currentMortgages?.length !== 0
                  ? formatCurrency(estimatedMortgageBalance) +
                    " | " +
                    formatCurrency(estimatedMortgagePayment) +
                    "/mo"
                  : "-"}
              </p>
              <h3>Taxes</h3>
              <p>{formatCurrency(taxInfo?.taxAmount / 12)}/mo</p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                Equity <small>(est.)</small>
              </h3>
              {propertyDetails?.jsonData && estimatedEquity ? (
                <>
                  <p>
                    {formatCurrency(estimatedEquity)} | {equityPercent}% |{" "}
                    {equityPercent < 35
                      ? "Low"
                      : equityPercent >= 35 && equityPercent <= 65
                      ? "Average"
                      : "High"}
                  </p>
                  <div className={`${styles.progress}`}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={equityPercent}
                    />
                  </div>
                </>
              ) : (
                "-"
              )}
              <h3>Est. Value</h3>
              <p>{formatCurrency(estimatedValue)}</p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>Last Sale</h3>
              <p>{formatCurrency(lastSalePrice)}</p>
              <h3>MLS</h3>
              <p>
                {mlsActive || mlsPending
                  ? formatCurrency(mlsHistory[0]?.price)
                  : "Inactive"}
              </p>
            </div>
            <div className={`${styles.propertyBox}`}>
              <h3>
                FMR <small>(HUD)</small>
              </h3>
              {/* <p>{formatCurrency(demographics.medianIncome)}/mo</p> */}
              <p>
                {propertyInfo?.bedrooms ? (
                  <>
                    {propertyInfo?.bedrooms === 0 &&
                      `${formatCurrency(demographics?.fmrEfficiency)}/mo`}
                    {propertyInfo?.bedrooms === 1 &&
                      `${formatCurrency(demographics?.fmrOneBedroom)}/mo`}
                    {propertyInfo?.bedrooms === 2 &&
                      `${formatCurrency(demographics?.fmrTwoBedroom)}/mo`}
                    {propertyInfo?.bedrooms === 3 &&
                      `${formatCurrency(demographics?.fmrThreeBedroom)}/mo`}
                    {propertyInfo?.bedrooms >= 4 &&
                      `${formatCurrency(demographics?.fmrFourBedroom)}/mo`}
                  </>
                ) : (
                  "-"
                )}
              </p>
              <h3>
                Rent <small>(est.)</small>
              </h3>
              <p>
                {demographics?.suggestedRent
                  ? `${formatCurrency(demographics?.suggestedRent)}/mo`
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toggleMarker = (id: number | string) => {
    if (selectedMarkers.includes(id)) {
      setSelectedMarkers((prev) => prev.filter((marker) => marker !== id));
    } else {
      setSelectedMarkers((prev) => [...prev, id]);
    }
  };

 

  return (
    <>
      <LeadActivityDrawer
        propertyId={param.id || ""}
        showCommentBox={showCommentBox}
        setShowpopup={setShowCommentBox}
      />
      <SingleSkipTraceModal
        propertyId={param.id || ""}
        show={showSingleSkipTraceModal}
        setShow={setshowSingleSkipTraceModal}
      />
      <AddContactModal 
        show={showAddContactModal}
        setShow={setShowAddContactModal}></AddContactModal>


      <div className={` ${styles.property}`}>
        {/* <Header loggedIn></Header> */}
        <AboveMap />
        <div className={` ${styles.property__map}`}>
          <Map
            selectedMarkers={selectedMarkers}
            height="471px"
            toggleMarker={toggleMarker}
            markers={[
              {
                id: propertyDetails?.jsonData?.id,
                coordinate: {
                  lat: propertyInfo?.latitude ? propertyInfo?.latitude : 0,
                  lng: propertyInfo?.longitude ? propertyInfo?.longitude : 0,
                },
                streetno: propertyInfo?.address?.street,
                component: (
                  <MarkerPopup
                    onClose={() => {
                      setSelectedMarkers((prev) =>
                        prev.filter(
                          (marker) => marker !== propertyDetails?.jsonData?.id
                        )
                      );
                    }}
                    headerText={label}
                    infoText={propertyfeaturesAsString}
                  ></MarkerPopup>
                ),
              },
            ]}
            coordinate={{
              lat: propertyInfo?.latitude ? propertyInfo?.latitude : 0,
              lng: propertyInfo?.longitude ? propertyInfo?.longitude : 0,
            }}
            initialViewMode={MapViewMode.HYBRID}
          />
        </div>
        <BelowMap />
        <div className={` ${styles.property__tabs}`}>
          <PropertyTabs handleClickSkipTrace={handleClickSkipTrace} 
          handleClickAddContact={handleClickAddContact}
          />
        </div>
      </div>
      {editNameModal && (
        <>
          <Dialog
            className="stepsDialog editModal"
            onClose={handleEditName}
            open={editNameModal}
          >
            <div className={`editModal`}>
              <label>Owner name*</label>
              <input
                placeholder="Owner name"
                type="text"
                defaultValue={ownerFullName}
                onChange={(e: any) => (nameRef.current = e.target.value.trim())}
                disabled={loading || resetLoading}
              />

              <div
                className={` editModal__button dflex justifyCenter`}
              >
                <div className={` editModal__col `}>
                  <Button
                    className="primary full animated"
                    label="Save"
                    action={handleSaveName}
                    clicked={loading}
                    disabled={loading || resetLoading}
                  />
                </div>
                <div className={` editModal__col `}>
                  <Button
                    className="outline full "
                    label="Reset"
                    action={handleResetName}
                    clicked={resetLoading}
                    disabled={resetLoading || loading || Object.keys(propertyDetails?.jsonData).length === 0}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};

export default PropertyDetails;
