import { useEffect, useState } from "react";
import CustomTable from "../../molecules/table/CustomTable";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../atoms/Loader";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import {
  fetchActiveUsersList,
  fetchPendingUsersList,
} from "../../pages/myTeam/sagaActions";
import {
  getActiveListLoading,
  getActiveUserList,
  getPendingUserList,
  getUserRoleList,
} from "../../pages/myTeam/selectors";
import { updateUserRole } from "../../pages/myTeam/TeamService";
import { MANAGE_TEAM_TABLE_HEADER } from "./constants";
import ManageTeamActions from "./ManageTeamActions";

const ActiveUsers = () => {
  const activeList = useSelector((state) => getActiveUserList(state));
  const dataLoading = useSelector((state) => getActiveListLoading(state));
  const pendingList = useSelector((state) => getPendingUserList(state));
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const roleList = useSelector((state) => getUserRoleList(state));
  const [roleListOptions, setRoleListOptions] = useState([]);

  useEffect(() => {
    const options = roleList.map((list: any) => ({
      value: list,
      label: list,
    }));
    setRoleListOptions(options);
  }, [roleList]);

  const handleStatusChange = async (list: any) => {
    const payload = {
      id: list.id,
      userRoleName: list?.value,
      isApprovedUser: list?.flag,
    };
    console.log(payload);

    try {
      await updateUserRole(payload);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const listData = [...pendingList, ...activeList]?.map((activeUser: any) => {
      return {
        id: activeUser?.id,
        name: `${activeUser?.nameFirst} ${
          activeUser?.nameLast ? activeUser?.nameLast : ""
        }`,
        email: activeUser?.email,
        role: profileInfo?.userRoleName,
        roleName: activeUser?.userRoleName,
        flag: activeUser?.status === "PENDING" ? false : true,
        rootUser: activeUser?.isRootUser ? activeUser?.isRootUser : false,
        actions: (
          <ManageTeamActions activeUser={activeUser} key={activeUser?.id} />
        ),
        activityDrawerLeadId: false,
      };
    });
    setTableData(listData ?? []);
  }, [activeList, pendingList, profileInfo]);

  useEffect(() => {
    dispatch(fetchActiveUsersList({}));
    dispatch(fetchPendingUsersList({}));
  }, []);

  return (
    <>
      {dataLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className="manage-team">
          <CustomTable
            rows={tableData}
            columns={MANAGE_TEAM_TABLE_HEADER}
            search={""}
            tableStriped
            // hasMore={tableData.length < total}
            options={roleListOptions}
            hasMore={false}
            handleClickedPill={handleStatusChange}
          ></CustomTable>
        </div>
      )}
    </>
  );
};

export default ActiveUsers;
