import React, { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../atoms/Input";
import { addUserSchema } from "./validations";
import MaskedInput from "../../atoms/MaskedInput";
import DropDown from "../../atoms/DropDown";
import { Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserRoleList } from "../../pages/myTeam/selectors";
import Close from "../../assets/icons/cross";

const AddUser = ({ handleClose, onSubmitDetail, loading, open }: any) => {
  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(addUserSchema),
    defaultValues:{
      name:"",
      email:"",
      userRoleName:"",
      phone:""
    }
  });
  const roleList = useSelector((state) => getUserRoleList(state));
  const [roleListOptions, setRoleListOptions] = useState([]);

  useEffect(() => {
    const options = roleList.map((list: any) => ({
      value: list,
      label: list,
    }));
    setRoleListOptions(options);
  }, [roleList]);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  return (
    <>
      <Dialog
        className="stepsDialog contactInfoModal"
        onClose={handleClose}
        open={open}
      >
        <div className="dialogWrapper">
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>
          <div className="dialogWrapper__header">
            <h2>Add User</h2>
          </div>
          <Input
            type="text"
            label="Name*"
            register={register}
            name="name"
            placeholder="Enter user name"
            errors={errors}
          ></Input>
          <Input
            type="email"
            label="Email*"
            register={register}
            name="email"
            placeholder="Enter user email"
            errors={errors}
          ></Input>
          <MaskedInput
            placeholder="Enter user phone number"
            label="Phone"
            name="phone"
            register={register}
            errors={errors}
            mask={"999-999-9999"}
          ></MaskedInput>

          <DropDown
            options={roleListOptions}
            label="Role*"
            control={control}
            name="userRoleName"
            errors={errors}
            placeholder="Select role"
          ></DropDown>

          <div className="authStyle__footer">
            <Button
              label="Submit"
              className="primary full xl animated"
              action={handleSubmit(onSubmitDetail)}
              clicked={loading}
              disabled={loading}
            ></Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddUser;
