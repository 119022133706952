
import { useForm } from "react-hook-form";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Logo from "../../components/Logo";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  loginUserFn,
  resetNewUserPassword,
  sendEmailForgotPass,
  setNewPassword,
} from "../auth/authServices";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { resetPasswordNewUserSchema } from "./validation";

const NewUserResetPassword  = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const searchUrl = new URLSearchParams(decodeURIComponent(window.location.search));
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(resetPasswordNewUserSchema),
  });

  

  const onSubmit = async (values: any) => {
    setSubmitClicked(true);
    const payload = {
      email: searchUrl.get("email") || "",
      code: searchUrl.get("code") || "",
      newPassword: values?.password,
    };

    if (executeRecaptcha) {
      const Token = await executeRecaptcha("Login");
      const loginPayload = {
        email: searchUrl.get("email") || "",
        password: values?.password,
        captchaToken: Token,
      };
      try {
        await resetNewUserPassword(payload);
        toast.success("Password Updated Successfully!");
        const loginUserData = await loginUserFn(loginPayload);
        localStorage.setItem("accessToken", loginUserData?.data?.access_token);
        localStorage.setItem("refreshToken", loginUserData?.data?.refresh_token);
        window.location.href = `${process.env.REACT_APP_FE_URL}`;
        navigate("/property-search");
      } catch (err) {
        console.error(err);
        toast.error("Link seems to be expired. Please resend the link!");
      } finally {
        setSubmitClicked(false);
      }

    } else{
      console.error('executeRecaptcha is undefined');
    }
  };

  useEffect(() => {
    if (watch("password") === watch("confirmPassword")) {
      clearErrors("confirmPassword");
    }
  }, [watch("password"), watch("confirmPassword")]);

  return (
    <div className="verifyEmail newPassword">
      <form className="verifyEmail__info ">
        <div className="authStyle__logo flex alignCenter justifyCenter">
          <Logo />
        </div>

        <div className="authStyle__header">
          <h2>Create password</h2>
          <p>Please enter your password below.</p>
        </div>
        <Input
          type="password"
          label="Password*"
          register={register}
          name="password"
          placeholder="Enter password"
          errors={errors}
        ></Input>
        <Input
          type="password"
          label="Confirm Password*"
          register={register}
          name="confirmPassword"
          placeholder="Re-enter password"
          errors={errors}
        ></Input>
        <div className="authStyle__footer">
          <Button
            label="Create password"
            className="primary full xl"
            action={handleSubmit(onSubmit)}
            clicked={submitClicked}
            disabled={submitClicked}
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default NewUserResetPassword;
